// project imports
import config from 'config';

// action - state management
import {
    SET_MENU,
    MENU_OPEN,
    SET_FONT_FAMILY,
    SET_BORDER_RADIUS
} from '../actions';

const INIT_STATE = {
    isOpen: [], // for active default menu
    defaultId: 'default',
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

const reducer = (state = INIT_STATE, action) => {
    let id;
    switch (action.type) {
        case MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        default: return { ...state };
    }
};

export default reducer;
