
/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// action - customization reducer
export const SET_MENU = 'SET_MENU';
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const MENU_OPEN = 'MENU_OPEN';
export const SET_FONT_FAMILY = 'SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = 'SET_BORDER_RADIUS';

export * from "./auth/actions";
