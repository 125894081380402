import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const Login = Loadable(lazy(() => import('views/user/Login')));
const Register = Loadable(lazy(() => import('views/user/Register')));
const Activate = Loadable(lazy(() => import('views/user/Activate')));
const RecoveryPassword = Loadable(lazy(() => import('views/user/Recover')));
const Password = Loadable(lazy(() => import('views/user/Password')));
const Error404 = Loadable(lazy(() => import('views/error-pages/Error404')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/register',
            element: <Register />
        },
        {
            path: '/recover',
            element: <RecoveryPassword />
        },
        {
            path: '/recover/password/:token',
            element: <Password />
        },
        {
            path: '/activate/:token',
            element: <Activate />
        },
        {
            path: '/error',
            element: <Error404 />
        },
        {
            path: '*',
            element: <Error404 />
        }
    ]
};

export default AuthenticationRoutes;
