export const endpointApiUrl = `https://api.alhambrashopping.com`
// export const endpointApiUrl = `http://127.0.0.1:8000`

export const tokenAPI = "9dced33cf4e42d3328c708d0d172f32522e5917e"

export const endpointApi = {
   url: endpointApiUrl,
   endpoint: {
      invoice: `${endpointApiUrl}/invoice/`,
      campaign: `${endpointApiUrl}/campaign/`,
      campaignGift: `${endpointApiUrl}/campaign/gift/user/`,
      campaignGiftUsers: `${endpointApiUrl}/campaign/gift/user/all`,
      establishment: `${endpointApiUrl}/establishment/`,
      province: `${endpointApiUrl}/province/`,
      user: `${endpointApiUrl}/user/`,
      login: `${endpointApiUrl}/login/`,
   },
}

export const groups = {
   seller: 1,
   sales_coordinator: 2,
   approver: 3,
}
