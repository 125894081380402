// material-ui
import logo from 'assets/images/alhambra-horizontal.svg';

// ==============================|| LOGO SVG ||============================== //

const LogoHorizontal = () => {
    return <img src={logo} alt="Alhambra" width="130" />;
};

export default LogoHorizontal;
