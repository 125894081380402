// assets
import { IconFileInvoice, IconUser, IconHome } from '@tabler/icons';

// constant
const icons = { IconFileInvoice, IconUser, IconHome };

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const app = {
    id: 'app',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Inicio',
            type: 'item',
            url: '/invoice',
            icon: icons.IconHome,
            breadcrumbs: true
        },
        {
            id: 'invoice',
            title: 'Facturas',
            type: 'item',
            url: '/invoice',
            icon: icons.IconFileInvoice,
            breadcrumbs: true
        },
        {
            id: 'account',
            title: 'Cuenta',
            type: 'item',
            url: '/user/account',
            icon: icons.IconUser,
            breadcrumbs: false
        }
    ]
};

export default app;
