import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// home routing
const HomePage = Loadable(lazy(() => import('views/home')));

// sample page routing
const InvoicePage = Loadable(lazy(() => import('views/app/Invoice')));
const InvoiceAddPage = Loadable(lazy(() => import('views/app/InvoiceAdd')));

const CampaignGift = Loadable(lazy(() => import('views/app/Gift')));

// account routing
const AccountPage = Loadable(lazy(() => import('views/user/Account')));

//error
const Error404 = Loadable(lazy(() => import('views/error-pages/Error404')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <Navigate to={'invoice'} />
        },
        {
            path: 'campaign/gift',
            children: [
                {
                    path: '',
                    element: <CampaignGift />
                },
            ]
        },
        {
            path: 'invoice',
            children: [
                {
                    path: '',
                    element: <InvoicePage />
                },
                {
                    path: 'add',
                    element: <InvoiceAddPage />
                }
            ]
        },
        {
            path: 'user',
            children: [
                {
                    path: 'account',
                    element: <AccountPage />
                }
            ]
        },
        {
            path: 'error',
            element: <Error404 />
        },
    ]
};

export default MainRoutes;
