import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { endpointApi } from "../../helper/DefaultValues";

import {
    LOGIN_USER,
    LOGOUT_USER,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
} from './actions';

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (username, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'username': username,
            'password': password
        })
    };
    return await fetch(endpointApi.endpoint.login, requestOptions).then(authUser => authUser.json()).catch(error => error)
}

const loginUserToken = async (token) => {
    return await fetch(endpointApi.endpoint.user + token, {
        method: 'get',
        headers: new Headers({
            'Authorization': 'token ' + token,
        })
    }).then(authUser => authUser.json()).catch(error => error)
}

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (!loginUser.token) {
            throw (loginUser.non_field_errors.join(''))
        }

        const dataUser = yield call(loginUserToken, loginUser.token);
        if (!dataUser.id) {
            throw (new Error('No se pudo encontrar el usuario'))
        }
        localStorage.setItem('user_token', loginUser.token)
        localStorage.setItem('user', JSON.stringify(dataUser));
        yield put(loginUserSuccess(dataUser));
        history('/invoice');

    } catch (error) {
        yield put(loginUserError(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield localStorage.removeItem('user');
        yield localStorage.removeItem('user_token');
        history('/login')
    } catch (error) {
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
    ]);
}